var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6" },
          [
            _c(
              "c-card",
              {
                staticClass: "cardClassDetailForm blackout-card",
                attrs: { title: "정전작업 상세정보", height: "405px" },
              },
              [
                _c("template", { slot: "card-detail" }, [
                  _c(
                    "div",
                    { staticClass: "col-xs-6 col-sm-6 col-md-4 col-lg-4" },
                    [
                      _c("c-text", {
                        attrs: {
                          disabled: !_vm.isWriting,
                          editable: _vm.editable,
                          label: "제어실차단기번호",
                          name: "blackoutCircuitBreakerNo",
                        },
                        on: { dataChange: _vm.dataChange },
                        model: {
                          value: _vm.supWork.blackoutCircuitBreakerNo,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.supWork,
                              "blackoutCircuitBreakerNo",
                              $$v
                            )
                          },
                          expression: "supWork.blackoutCircuitBreakerNo",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-xs-6 col-sm-6 col-md-4 col-lg-4" },
                    [
                      _c("c-text", {
                        attrs: {
                          disabled: !_vm.isWriting,
                          editable: _vm.editable,
                          label: "제어실차단기기",
                          name: "blackoutCircuitBreaker",
                        },
                        on: { dataChange: _vm.dataChange },
                        model: {
                          value: _vm.supWork.blackoutCircuitBreaker,
                          callback: function ($$v) {
                            _vm.$set(_vm.supWork, "blackoutCircuitBreaker", $$v)
                          },
                          expression: "supWork.blackoutCircuitBreaker",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "col-12 q-mb-sm line-text-left" }, [
                    _c("div", { staticClass: "text-caption text-grey" }, [
                      _vm._v(" 차단 확인자 "),
                    ]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "col-xs-6 col-sm-6 col-md-4 col-lg-4" },
                    [
                      _c("c-field", {
                        attrs: {
                          disabled: !_vm.isWriting,
                          editable: _vm.editable,
                          data: _vm.supWork,
                          plantCd: _vm.workPermit.plantCd,
                          vendorCd: _vm.workPermit.vendorCd,
                          isVendorOrg: true,
                          changeItem: _vm.changeItem,
                          deptValue: "blackoutElectricBlockDeptCd",
                          type: "dept_user",
                          label: "전기담당자",
                          beforeText: "",
                          name: "blackoutElectricBlockUserId",
                        },
                        on: { dataChange: _vm.dataChange },
                        model: {
                          value: _vm.supWork.blackoutElectricBlockUserId,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.supWork,
                              "blackoutElectricBlockUserId",
                              $$v
                            )
                          },
                          expression: "supWork.blackoutElectricBlockUserId",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-xs-6 col-sm-6 col-md-4 col-lg-4" },
                    [
                      _c("c-field", {
                        attrs: {
                          disabled: !_vm.isWriting,
                          editable: _vm.editable,
                          data: _vm.supWork,
                          plantCd: _vm.workPermit.plantCd,
                          vendorCd: _vm.workPermit.vendorCd,
                          isVendorOrg: true,
                          changeItem: _vm.changeItem,
                          deptValue: "blackoutMaintenanceBlockDeptCd",
                          type: "dept_user",
                          label: "현장정비자",
                          beforeText: "",
                          name: "blackoutMaintenanceBlockUserId",
                        },
                        on: { dataChange: _vm.dataChange },
                        model: {
                          value: _vm.supWork.blackoutMaintenanceBlockUserId,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.supWork,
                              "blackoutMaintenanceBlockUserId",
                              $$v
                            )
                          },
                          expression: "supWork.blackoutMaintenanceBlockUserId",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "col-12 q-mb-sm line-text-left" }, [
                    _c("div", { staticClass: "text-caption text-grey" }, [
                      _vm._v(
                        " 전원복구 : 모든 작업이 완료된 후 운전부서의 입회자의 요청에 의해서만 전원을 복구하여야 한다. "
                      ),
                    ]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "col-xs-6 col-sm-6 col-md-4 col-lg-4" },
                    [
                      _c("c-field", {
                        attrs: {
                          disabled: !_vm.isWriting,
                          editable: _vm.editable,
                          data: _vm.supWork,
                          plantCd: _vm.workPermit.plantCd,
                          vendorCd: _vm.workPermit.vendorCd,
                          isVendorOrg: true,
                          changeItem: _vm.changeItem,
                          deptValue: "blackoutPowerRecoveryRequestDeptCd",
                          type: "dept_user",
                          label: "요청자",
                          beforeText: "",
                          name: "blackoutPowerRecoveryRequestUserId",
                        },
                        on: { dataChange: _vm.dataChange },
                        model: {
                          value: _vm.supWork.blackoutPowerRecoveryRequestUserId,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.supWork,
                              "blackoutPowerRecoveryRequestUserId",
                              $$v
                            )
                          },
                          expression:
                            "supWork.blackoutPowerRecoveryRequestUserId",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-xs-6 col-sm-6 col-md-4 col-lg-4" },
                    [
                      _c("c-datepicker", {
                        attrs: {
                          disabled: !_vm.isWriting,
                          editable: _vm.editable,
                          minuteStep: 10,
                          type: "time",
                          label: "시간",
                          name: "blackoutPowerRecoveryTime",
                        },
                        on: { dataChange: _vm.dataChange },
                        model: {
                          value: _vm.supWork.blackoutPowerRecoveryTime,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.supWork,
                              "blackoutPowerRecoveryTime",
                              $$v
                            )
                          },
                          expression: "supWork.blackoutPowerRecoveryTime",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ],
              2
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6" },
          [
            _c("c-table", {
              attrs: {
                title: "안전조치 요구사항",
                columns: _vm.grid.columns,
                gridHeight: _vm.grid.height,
                data: _vm.supWork.checkResults,
                filtering: false,
                columnSetting: false,
                usePaging: false,
                editable: _vm.editable && _vm.isWriting,
                contentsField: _vm.contentsField,
              },
            }),
          ],
          1
        ),
      ]),
      _vm.editable && _vm.isWriting
        ? _c(
            "div",
            {
              staticClass:
                "alert alert-info alert-dismissible fade show cursor-pointer",
              staticStyle: {
                "margin-bottom": "13px !important",
                padding: "10px 3px !important",
              },
              attrs: { role: "alert" },
              on: { click: _vm.setPermitData },
            },
            [
              _c(
                "span",
                { staticClass: "q-pl-sm q-pr-md" },
                [
                  _c("q-icon", {
                    attrs: { name: "o_emoji_objects", size: "sm" },
                  }),
                ],
                1
              ),
              _c(
                "q-chip",
                { attrs: { dense: "", color: "teal", "text-color": "white" } },
                [_vm._v(" 발급 ")]
              ),
              _c(
                "q-chip",
                { attrs: { dense: "", color: "teal", "text-color": "white" } },
                [_vm._v(" 협조자 ")]
              ),
              _c(
                "q-chip",
                { attrs: { dense: "", color: "teal", "text-color": "white" } },
                [_vm._v(" 안전조치확인 ")]
              ),
              _vm._v(" 정보를 허가서에서 가져오기 "),
            ],
            1
          )
        : _vm._e(),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4" },
          [
            _c(
              "c-card",
              {
                staticClass: "cardClassDetailForm no-margin",
                attrs: { title: "발급 정보" },
              },
              [
                _c("template", { slot: "card-detail" }, [
                  _c(
                    "div",
                    { staticClass: "col-6" },
                    [
                      _c("c-field", {
                        attrs: {
                          disabled: !_vm.isWriting,
                          editable: _vm.editable,
                          data: _vm.supWork,
                          plantCd: _vm.workPermit.plantCd,
                          vendorCd: _vm.workPermit.vendorCd,
                          isVendorOrg: true,
                          changeItem: _vm.changeItem,
                          unification: true,
                          userName: "",
                          deptValue: "issuedDeptCd",
                          type: "dept_user",
                          label: "발급",
                          beforeText: "",
                          name: "userId1",
                        },
                        on: { dataChange: _vm.dataChange },
                        model: {
                          value: _vm.supWork.issuedUserId,
                          callback: function ($$v) {
                            _vm.$set(_vm.supWork, "issuedUserId", $$v)
                          },
                          expression: "supWork.issuedUserId",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-6" },
                    [
                      _c("c-field", {
                        attrs: {
                          disabled: !_vm.isWriting,
                          editable: _vm.editable,
                          data: _vm.supWork,
                          plantCd: _vm.workPermit.plantCd,
                          vendorCd: _vm.workPermit.vendorCd,
                          isVendorOrg: true,
                          changeItem: _vm.changeItem,
                          userName: "",
                          deptValue: "approvalDeptCd",
                          type: "dept_user",
                          label: "승인",
                          beforeText: "",
                          name: "userId2",
                        },
                        on: { dataChange: _vm.dataChange },
                        model: {
                          value: _vm.supWork.approvalUserId,
                          callback: function ($$v) {
                            _vm.$set(_vm.supWork, "approvalUserId", $$v)
                          },
                          expression: "supWork.approvalUserId",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ],
              2
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4" },
          [
            _c(
              "c-card",
              {
                staticClass: "cardClassDetailForm no-margin",
                attrs: { title: "협조자 정보" },
              },
              [
                _c("template", { slot: "card-detail" }, [
                  _c(
                    "div",
                    { staticClass: "col-6" },
                    [
                      _c("c-field", {
                        attrs: {
                          disabled: !_vm.isWriting,
                          editable: _vm.editable,
                          data: _vm.supWork,
                          plantCd: _vm.workPermit.plantCd,
                          vendorCd: _vm.workPermit.vendorCd,
                          isVendorOrg: true,
                          changeItem: _vm.changeItem,
                          userName: "",
                          deptValue: "relationCooperation1DeptCd",
                          type: "dept_user",
                          label: "협조자-1",
                          beforeText: "",
                          name: "relationCooperation1UserId",
                        },
                        on: { dataChange: _vm.dataChange },
                        model: {
                          value: _vm.supWork.relationCooperation1UserId,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.supWork,
                              "relationCooperation1UserId",
                              $$v
                            )
                          },
                          expression: "supWork.relationCooperation1UserId",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-6" },
                    [
                      _c("c-field", {
                        attrs: {
                          disabled: !_vm.isWriting,
                          editable: _vm.editable,
                          data: _vm.supWork,
                          plantCd: _vm.workPermit.plantCd,
                          vendorCd: _vm.workPermit.vendorCd,
                          isVendorOrg: true,
                          changeItem: _vm.changeItem,
                          userName: "",
                          deptValue: "relationCooperation2DeptCd",
                          type: "dept_user",
                          label: "협조자-2",
                          beforeText: "",
                          name: "relationCooperation2UserId",
                        },
                        on: { dataChange: _vm.dataChange },
                        model: {
                          value: _vm.supWork.relationCooperation2UserId,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.supWork,
                              "relationCooperation2UserId",
                              $$v
                            )
                          },
                          expression: "supWork.relationCooperation2UserId",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ],
              2
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4" },
          [
            _c(
              "c-card",
              {
                staticClass: "cardClassDetailForm no-margin",
                attrs: { title: "안전조치확인 정보" },
              },
              [
                _c("template", { slot: "card-detail" }, [
                  _c(
                    "div",
                    { staticClass: "col-6" },
                    [
                      _c("c-field", {
                        attrs: {
                          disabled: !_vm.isWriting,
                          editable: _vm.editable,
                          data: _vm.supWork,
                          plantCd: _vm.workPermit.plantCd,
                          vendorCd: _vm.workPermit.vendorCd,
                          isVendorOrg: true,
                          changeItem: _vm.changeItem,
                          userName: "",
                          deptValue: "maintenanceDeptCheckDeptCd",
                          type: "dept_user",
                          label: "책임자",
                          name: "maintenanceDeptCheckUserId",
                        },
                        on: { dataChange: _vm.dataChange },
                        model: {
                          value: _vm.supWork.maintenanceDeptCheckUserId,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.supWork,
                              "maintenanceDeptCheckUserId",
                              $$v
                            )
                          },
                          expression: "supWork.maintenanceDeptCheckUserId",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-6" },
                    [
                      _c("c-field", {
                        attrs: {
                          disabled: !_vm.isWriting,
                          editable: _vm.editable,
                          data: _vm.supWork,
                          plantCd: _vm.workPermit.plantCd,
                          isVendorOrg: true,
                          changeItem: _vm.changeItem,
                          userName: "",
                          deptValue: "maintenanceDeptEntryDeptCd",
                          type: "dept_user",
                          label: "입회자",
                          name: "maintenanceDeptEntryUserId",
                        },
                        on: { dataChange: _vm.dataChange },
                        model: {
                          value: _vm.supWork.maintenanceDeptEntryUserId,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.supWork,
                              "maintenanceDeptEntryUserId",
                              $$v
                            )
                          },
                          expression: "supWork.maintenanceDeptEntryUserId",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ],
              2
            ),
          ],
          1
        ),
      ]),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }